import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import HeroComponent from '../components/Hero/Hero'

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props;

  const shuffleArray = (array) => {
    return array
      .reduce((acc, _, i) => {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        acc.splice(randomIndex, 0, array[i]);
        return acc;
      }, []);
  };

  const featuredPostsReordenado = shuffleArray(featuredPosts.nodes);
  const featuredPostsReordenado1 = [featuredPostsReordenado[0], featuredPostsReordenado[1], featuredPostsReordenado[2]];
  const featuredPostsReordenado2 = featuredPostsReordenado.slice(3);
  const postCat1Reordenado = shuffleArray(posts.group[0].nodes);
  const postCat1Reordenado1 = [postCat1Reordenado[0]];
  const postCat1Reordenado2 = postCat1Reordenado.slice(1);
  const postCat2Reordenado = shuffleArray(posts.group[1].nodes);
  const postCat2Reordenado1 = [postCat2Reordenado[0]];
  const postCat2Reordenado2 = [postCat2Reordenado[1]];
  const postCat2Reordenado3 = [postCat2Reordenado[2], postCat2Reordenado[3]];
  const postCat2Reordenado4 = [postCat2Reordenado[4], postCat2Reordenado[5]];
  const postCat3Reordenado = shuffleArray(posts.group[2].nodes);
  const postCat3Reordenado1 = [postCat3Reordenado[0]];
  const postCat3Reordenado2 = postCat3Reordenado.slice(1);
  const postCat4Reordenado = shuffleArray(posts.group[3].nodes);
  const postCat4Reordenado1 = [postCat4Reordenado[0]];
  const postCat4Reordenado2 = [postCat4Reordenado[1]];
  const postCat4Reordenado3 = [postCat4Reordenado[2], postCat4Reordenado[3]];
  const postCat4Reordenado4 = [postCat4Reordenado[4], postCat4Reordenado[5]];
  const postCat5Reordenado = shuffleArray(posts.group[4].nodes);
  const postCat5Reordenado1 = [postCat5Reordenado[0]];
  const postCat5Reordenado2 = postCat5Reordenado.slice(1);

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <CardList
          nodes={featuredPostsReordenado1}
          limit={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'vertical-cover']}
          title="Random Advisor Instances"
          loading='eager'
          omitCategory
        />
      </Stack>
      <Divider space={2} />
      <Stack>
        <CardList
          nodes={featuredPostsReordenado2}
          limit={6}
          //skip={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'horizontal-aside']}
          loading='eager'
        />
      </Stack>
      <Divider />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent />
      </Hero>
      <Divider />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index % 2 === 0 ? (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={index === 0 ? postCat1Reordenado2 : index === 2 ? postCat3Reordenado2 : postCat5Reordenado2}
                    limit={2}
                    variant={['horizontal-md', 'horizontal']}
                  />
                </Main>
                <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
                  <CardList
                    nodes={index === 0 ? postCat1Reordenado1 : index === 2 ? postCat3Reordenado1 : postCat5Reordenado1}
                    limit={1}
                    //skip={2}
                    variant={['horizontal-md', 'vertical']}
                  />
                </Sidebar>
              </Stack>
            ) : (
              <Stack
                effectProps={{ effect: 'fadeInLeft' }}
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={index === 1 ? postCat2Reordenado2 : postCat4Reordenado2}
                    limit={1}
                    variant={['horizontal-md', 'horizontal']}
                  />
                  <Divider space={2} />
                  <CardList
                    nodes={index === 1 ? postCat2Reordenado3 : postCat4Reordenado3}
                    limit={2}
                    //skip={2}
                    columns={[1, 2]}
                    variant={['horizontal-md', 'horizontal-aside']}
                    omitMedia
                    omitCategory
                  />
                  <Divider space={2} />
                  <CardList
                    nodes={index === 1 ? postCat2Reordenado4 : postCat4Reordenado4}
                    limit={2}
                    //skip={4}
                    columns={[1, 2]}
                    variant={['horizontal-md', 'horizontal-aside']}
                    omitMedia
                    omitCategory
                  />
                </Main>
                <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
                  <CardList
                    nodes={index === 1 ? postCat2Reordenado1 : postCat4Reordenado1}
                    limit={1}
                    //skip={1}
                    variant={['horizontal-md', 'vertical']}
                  />
                </Sidebar>
              </Stack>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Divider space={5} />
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
